import axios from 'axios'
import { useMembershipStore } from '~/stores/membership'
import { useGoogleAnalyticsDownloadEvent } from '~/composables/google-analytics'
import { logError } from '@/utils/log-error'

export function useDownloadFile() {
  const { $token, $config, $auth, $modal } = useNuxtApp()
  const membershipStore = useMembershipStore()
  const route = useRoute()

  const downloadProgress = ref(0)
  const isDownloading = ref(false)
  const finishDownload = ref(false)
  let abortController

  async function getFile(id: string, category: string): Promise<any> {
    const path = `/downloads/category/${category}/${id}`
    const baseUrl = $config.apiRestEndpoint
    const url = `${baseUrl || $config.baseUrl}${path}`
    const headers: Record<string, any> = await $token.getAuthorizationHeader()

    abortController = new AbortController()

    return axios({
      method: 'post',
      url,
      responseType: 'blob',
      headers: headers.headers,
      signal: abortController.signal,
      onDownloadProgress: (progressEvent) => {
        finishDownload.value = false
        const total = parseFloat(progressEvent.total)
        const current = progressEvent.loaded
        setDownloadProgress(Math.floor((current / total) * 100))
      },
    }).finally(resetDownloadState)
  }

  function setDownloadProgress(progress: number) {
    if (!isDownloading.value && abortController === undefined) return
    downloadProgress.value = progress
  }

  function resetDownloadState() {
    isDownloading.value = false
    downloadProgress.value = 0
    finishDownload.value = true
    abortController = undefined
  }

  function cancelDownload() {
    if (!abortController) return

    abortController.abort()
    resetDownloadState()
  }

  async function downloadFile(id: string, category: string, fileName: string) {
    isDownloading.value = true
    let blob: Blob

    try {
      blob = (await getFile(id, category)).data
      useGoogleAnalyticsDownloadEvent(fileName)
    } catch (error) {
      isDownloading.value = false
      return
    }

    const blobUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = blobUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(blobUrl)
    isDownloading.value = false
  }

  function onClickDownload(id: string, category: string, fileName: string) {
    if (downloadProgress.value || isDownloading.value) return cancelDownload()

    const fileExtension = fileName.split('.').pop()?.toLowerCase()

    const validExtensions = ['doc', 'docx', 'dotx', 'pdf', 'xls', 'xlsx']

    if (!fileExtension || !validExtensions.includes(fileExtension)) {
      logError('Invalid file extension or missing file extension.')
      return
    }

    downloadFile(id, category, fileName)
  }

  const callToAction = computed(() => {
    if (!$auth.store.isAuthenticated && !isSpecialIssue.value)
      return {
        text: 'Login',
        callback: () => {
          $modal.open('login')
        },
      }
    if (!membershipStore.isSubscriberActive && !isSpecialIssue.value)
      return {
        text: 'Subscribe',
        callback: () => {
          $modal.open('requestATrial')
        },
      }

    return {
      text: 'Download',
      callback: onClickDownload,
    }
  })

  const isSpecialIssue = computed(() => {
    return (
      route.name === 'downloads-special-issues-id' ||
      $modal.downloadSpecialIssue
    )
  })

  return {
    callToAction,
    isDownloading,
    downloadProgress,
    cancelDownload,
    finishDownload,
  }
}
