import * as Sentry from '@sentry/vue'
import * as pkg from '~/package.json'

export default defineNuxtPlugin(({ $router, vueApp, $config }) => {
  if ($config.sentry.enabled) {
    const release = `${pkg.name}@${pkg.version}`

    Sentry.init({
      app: vueApp,
      dsn: $config.sentry.dsn,
      release,
      environment: $config.sentry.environment,
      integrations: [
        Sentry.browserTracingIntegration({
          router: $router,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
      ],
      trackComponents: true,
      sampleRate: 0.5,
      tracesSampleRate: 0.3,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.3,
    })
  }
})
