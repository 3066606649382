import {
  ApolloClient,
  ApolloQueryResult,
  DefaultContext,
  FetchResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
} from '@apollo/client/core'

export interface ApolloClientProxyOptions {
  maxNumberOfRetries?: number
}

export interface HttpClientProxyOptions extends ApolloClientProxyOptions {}

class ApolloClientProxy {
  apolloClient: ApolloClient<any>
  tokenPlugin: any

  currentRetries = 0
  maxNumberOfRetries = 0
  INTERNAL_SERVER_ERROR = 'Internal server error'

  constructor(
    apolloClient,
    $tokenPlugin,
    options: ApolloClientProxyOptions = {},
  ) {
    this.apolloClient = apolloClient
    this.maxNumberOfRetries = options.maxNumberOfRetries || 0
    this.tokenPlugin = $tokenPlugin
  }

  async query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables, T>,
  ): Promise<ApolloQueryResult<T>> {
    const result = await this.apolloClient.query(options)
    const errors = result.errors || []

    if (
      errors[0]?.message === this.INTERNAL_SERVER_ERROR &&
      this.tokenPlugin.refreshTokenExists() &&
      this.currentRetries < this.maxNumberOfRetries
    ) {
      this.currentRetries++
      await this.tokenPlugin.refreshTokenRequest()
      return this.query(options)
    }
    return result
  }

  mutate<
    TData = any,
    TVariables = OperationVariables,
    TContext = DefaultContext,
  >(
    options: MutationOptions<TData, TVariables, TContext>,
  ): Promise<FetchResult<TData>> {
    return this.apolloClient.mutate(options)
  }
}

class HttpClientProxy {
  fetcher
  maxNumberOfRetries = 0
  currentRetries = 0
  EXPIRED_TOKEN_MESSAGE = 'Expired token'
  tokenPlugin: any

  constructor($fetcher, $tokenPlugin, options: HttpClientProxyOptions = {}) {
    this.fetcher = $fetcher
    this.maxNumberOfRetries = options.maxNumberOfRetries || 0
    this.tokenPlugin = $tokenPlugin
  }

  async request<TType>(
    url: string,
    method: string,
    body: object | FormData,
    params: object,
  ): Promise<TType> {
    const headers = await this.tokenPlugin.getAuthorizationHeader()
    const options = {
      headers: { ...headers.headers, 'X-Source': 'Source' },
      method,
      body,
      params,
    }
    try {
      return await this.fetcher(url, options)
    } catch (error) {
      if (
        error?.response?._data?.message === this.EXPIRED_TOKEN_MESSAGE &&
        this.tokenPlugin.refreshTokenExists() &&
        this.currentRetries < this.maxNumberOfRetries
      ) {
        this.currentRetries++
        await this.tokenPlugin.refreshTokenRequest()
        return this.request(url, method, body, params)
      }

      throw error
    }
  }
}

export { ApolloClientProxy, HttpClientProxy }
